import React from 'react';
import Layout from "../../components/Layout";

export default function () {
  return <Layout>
    <div className={'site-map container'}>
      <h1 className={'title'}>Site Map</h1>
      <div className={'content'}>
        <ul>
          <li><a href="http://www.littlerassociates.com/">home</a></li>
          <li><a href="http://www.littlerassociates.com/privacy-policy/">privacy-policy</a>
          </li>
          <li><a href="http://www.littlerassociates.com/what-we-have-learnt/">what-we-have-learnt</a></li>
          <li><a href="http://www.littlerassociates.com/what-we-do/">what-we-do</a></li>
          <li><a href="http://www.littlerassociates.com/what-we-have-done/">what-we-have-done</a></li>
          <li><a href="http://www.littlerassociates.com/blog/">blog</a></li>
          <li><a href="http://www.littlerassociates.com/contact/">contact</a></li>
          <li><a href="http://www.littlerassociates.com/faq/">faq</a></li>
          <li><a href="http://www.littlerassociates.com/about-us/">about-us</a></li>
          <li><a href="http://www.littlerassociates.com/blog/2020-09-15-how-do-develop-a-view-of-a-business/">blog/2020-09-15-how-do-develop-a-view-of-a-business</a></li>
          <li><a href="http://www.littlerassociates.com/clients/">clients</a></li>
          <li><a href="http://www.littlerassociates.com/blog/2020-09-15-what-qualities-do-we-need-to-hire-for/">blog/2020-09-15-what-qualities-do-we-need-to-hire-for</a></li>
          <li><a href="http://www.littlerassociates.com/blog/2020-09-15-porter’s-five-forces/">blog/2020-09-15-porter’s-five-forces</a></li>
          <li><a href="http://www.littlerassociates.com/blog/2020-09-15-how-to-guard-against-biases/">blog/2020-09-15-how-to-guard-against-biases</a></li>
          <li><a href="http://www.littlerassociates.com/blog/2020-09-15-3-good-reasons-to-hire-consultants/">blog/2020-09-15-3-good-reasons-to-hire-consultants/</a></li>
          <li><a href="http://www.littlerassociates.com/blog/2020-09-15-business-schools-in-india/">blog/2020-09-15-business-schools-in-india/</a></li>
          <li><a href="http://www.littlerassociates.com/blog/2020-09-15-multiple-brands/">blog/2020-09-15-multiple-brands/</a></li>
          <li><a href="http://www.littlerassociates.com/blog/2020-09-15-focus-in-consulting/">blog/2020-09-15-focus-in-consulting/</a></li>
          <li><a href="http://www.littlerassociates.com/blog/2020-09-15-testing-a-marketing-program/">blog/2020-09-15-testing-a-marketing-program/</a></li>
          <li><a href="http://www.littlerassociates.com/blog/2020-09-15-theory-of-constraints/">blog/2020-09-15-theory-of-constraints/</a></li>
          <li><a href="http://www.littlerassociates.com/blog/2020-09-15-what-is-the-role-of-the-channel/">blog/2020-09-15-what-is-the-role-of-the-channel/</a></li>
          <li><a href="http://www.littlerassociates.com/tags/strategy/">/tags/strategy/</a></li>
          <li><a href="http://www.littlerassociates.com/tags/consulting/">/tags/consulting/</a></li>
          <li><a href="http://www.littlerassociates.com/what-we-do-not-do/">what-we-do-not-do/</a></li>
          <li><a href="http://www.littlerassociates.com/tags/education/">/tags/education/</a></li>
          <li><a href="http://www.littlerassociates.com/tags/marketing/">/tags/marketing/</a></li>
          <li><a href="http://www.littlerassociates.com/tags/">/tags/</a></li>
        </ul>www.
      </div>
    </div>
  </Layout>
}
